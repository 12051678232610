<template>
  <div>
    <Card>
      <template slot="outer">
        <PageTopOuter
          icon="campaign"
          label="PTE Quiz"
          :search-box="false"
          :show-dialog="false"
          class="mt-4"
          @on-create="onCreateNew"
        />
      </template>
      <CardBody class="p-3">
        <div class="mb-3 flex justify-between flex-wrap justify-sm-center justify-xs-center">
          <!-- it will be Sort by date -->
          <div class="flex items-center">
            <SelectComponent
                class="w-full"
                :placeholder="false"
                return-type="object"
                @getObject="onChangeQuestionType"
                :value="selectQuestionType"
                :items="[{ slug: 'All',name: 'All Type', id: 'all' }, ...questionTypes]"
            />
            <SelectComponent
              :placeholder="false"
              class="w-32 m-2"
              :items="statuses"
              return-type="index"
              @getIndex="getStatus"
            />
          </div>
          <SearchBox
            v-model="keyword"
            placeholder="Search"
            class="p-2 w-64 m-2"
            @submit="submitSearchKeyword"
            />
        </div>
        <md-divider></md-divider>
        <Table :sort-by="default_sort" :items="questions" hover-action @onSort="setSortQuestion">
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-label="SL" md-numeric>{{ item.sl }}</md-table-cell>
            <md-table-cell md-sort-by="q_id" md-label="Q_ID">{{ item.quiz_id }}</md-table-cell>
            <md-table-cell md-sort-by="index" md-label="Q_TITLE"> {{ item.index }} </md-table-cell>
            <md-table-cell md-sort-by="quiz_type_title" md-label="Q.TYPE" style="width:'110px';font-size:16px;font-weight:600;text-align:center; white-space: nowrap">
              {{item.quiz_type_title === 'Text+Text' ? 'Τ+Τ' : item.quiz_type_title === 'Image+Text' ? '📸+Τ' : item.quiz_type_title === 'Video+Text' ? '🎬+Τ': '♫+Τ'}}
            </md-table-cell>
            <md-table-cell md-sort-by="answer_type" md-label="A.TYPE" style="font-size:20px;font-weight:600;text-align:center">
            {{ item.answer_type === 'MCQ-CHOOSE-SINGLE-ANSWER' ? '◉' : item.answer_type === 'MCQ-CHOOSE-MULTIPLE-ANSWER' ? '☑': 'Τ' }}
            </md-table-cell>
            <md-table-cell md-sort-by="tag" md-label="TAGS"><span class="ellipsis"><span v-for="(tag,i) in item.tags" :key="i">{{ tag }} , </span></span></md-table-cell>
            <md-table-cell md-sort-by="created_at" md-label="CREATED AT">{{ item.created_at }}</md-table-cell>
            <md-table-cell md-label="CREATED BY" class="text-victoria ">
              <span @click="showCreatedByProfile(item.updated_by)" class="table-cell-link">{{ item.created_by }}</span>
            </md-table-cell>
            <md-table-cell md-label="Mark" class="text-green">{{item.total_mark}}</md-table-cell>
            <md-table-cell md-sort-by="active" md-label="STATUS" class="text-green">{{ (item.active == 1) ? 'Published' : 'Draft' }}</md-table-cell>
            <md-table-cell>
              <span class="action flex">
                <md-icon  @click.native="onDetails(item.id)" class="bg-blue-chill rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
                <md-icon @click.native="onEditQuiz(item.id)" class="bg-victoria rounded-full p-1 text-white text-base mr-1">edit</md-icon>
                <md-icon @click.native="deleteQuestion(item.id)" class="bg-tahiti-gold text-white rounded-full p-1 text-base">delete</md-icon>
              </span>
            </md-table-cell>
          </template>
        </Table>
      </CardBody>
    </Card>
    <div class="flex justify-end my-6">
      <Paginate
        :start="meta.from"
        :end="meta.to"
        :next="meta.current_page"
        :prev="meta.last_page"
        :total="meta.total"
        :limit="meta.per_page"
        @on-start="onStart"
        @on-end="onEnd"
      />
    </div>
    <ConfirmDialog 
      @confirm="actDeleteQuiz(questionId)"
      :active.sync="active"
    />
     <Dialog class="md-dailog__teacher" :class="component === 'CreatedByProfile' && 'default-width'">
          <components :is="component"></components>
      </Dialog>
  </div>
</template>

<script>
import {
  Card,
  CardBody,
  SelectComponent,
  ConfirmDialog,
  PageTopOuter,
  Table,
  Paginate
  } from "@/components";
import SearchBox from "@/components/atom/SearchBox";
import Dialog from "@/components/atom/Dialog";
import QuizDetails from "./QuizDetails.vue";
//import { find } from 'lodash';
import { mapMutations, mapActions, mapGetters } from 'vuex';
//import cms from "@/data/cms"
import { query } from "@/utils/queryString";
import data from "@/data/pte-quiz/data";
import CreatedByProfile from "@/components/molecule/user/CreatedByProfile";

export default {
  name: "SpeakingQuestionListView",
  components: {
    Card,
    Paginate,
    CardBody,
    PageTopOuter,
    ConfirmDialog,
    Dialog,
    SelectComponent,
    SearchBox,
    Table,
    QuizDetails,
    CreatedByProfile
  },
  data() {
    return {
      active: false,
      pagination: true,
      questionTypes: data.questionTypes,
      component: 'QuizDetails',
      default_sort: 'index',
      keyword: '',
      selectQuestionType: 'all'
    }
  },
   computed: {
    ...mapGetters({
      questions: "question/getQuizWiseQuestion",
      meta:"question/getQuizWiseQuestionMeta",
      statuses: "cms/getStatuses",
    })
  },
  watch: {
    keyword(previous, current) {
        query.set('search', previous)
        if(previous.length < current.length && previous.length == 0) {
          this.actQuizWiseQuestion(`?${query.get()}`);
        }
        if(previous.length >= 3) {
          this.actQuizWiseQuestion(`?${query.get()}`);
        }
    }
  },
  methods: {
     ...mapMutations({
      dialog: "setShowDialog",
    }),
    ...mapActions({
      actGetQuestionTypes: "question/actGetQuestionTypes",
      actQuizWiseQuestion: "question/actQuizWiseQuestion",
      actGetDropdowns: "cms/actGetDropdowns",
      actDeleteQuiz: "question/actDeleteQuiz",
      actQuizDetails: "question/actQuizDetails",
      actGetUserInformation: "user/actGetUserInformation",
    }),
    onDetails(questionId) {
      this.component = 'QuizDetails';
      this.dialog(true);
      this.actQuizDetails(questionId);
    },
    onEditQuiz(questionId) {
      this.$router.push({
        name: 'pte-quiz.edit',
        params: {
          id: questionId
        },
      })
    },
    deleteQuestion(questionId) {
      this.active = true;
      this.questionId = questionId;
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
    onChangeQuestionType(item){
      query.set('type', item.type);
      if(item.id == 'all') {
        query.remove('type');
      } else {
        query.set('type', item.type);
      }
      this.actQuizWiseQuestion(`?${query.get()}`);
      query.set('page', 1);
      this.selectQuestionType = item.id
    },
    getStatus(index) {
      this.selectedValue = this.toLower(this.statuses[index].id);
      if(this.selectedValue == 'all') {
        query.remove('active');
      }else {
        query.set('active', this.selectedValue);
      }
      this.actQuizWiseQuestion(`?${query.get()}`);
      query.set('page', 1);
    },
    submitSearchKeyword() {
      this.keyword ? query.set('search', this.keyword) : query.remove('search'); 
      this.keyword && query.set('page', 1) 
      this.actQuizWiseQuestion(`?${query.get()}`);
    },
    showCreatedByProfile(createdById) {
      this.component = 'CreatedByProfile';
      this.dialog(true);
     this.actGetUserInformation(createdById);
    },
    showUsedForList(questionId) {
      this.$emit('on-click', {
        component: 'QuestionUsedForList'
      });
      this.actQuestionDetails(questionId);
    },
    getIconColor(frequency) {
      let frequencyObject = find(this.frequencyStyles, ['id', frequency]);
      return frequencyObject ? frequencyObject.color : ''
    },
    onCreateNew() {
      this.$router.push({
        name: 'pte-quiz.create',
      });
    },
    onStart(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actQuizWiseQuestion(`?${query.get()}`);
    },
    onEnd(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actQuizWiseQuestion(`?${query.get()}`);
    },
    setSortQuestion(sort){
      const sortString = sort.order === 'asc' ? sort.sortBy : `-${sort.sortBy}`;
      query.set('sort', sortString);
      this.actQuizWiseQuestion(`?${query.get()}`);
    },
  },
  created() {
    query.clear()
    query.set('sort', this.default_sort);
    this.actQuizWiseQuestion(`?${query.get()}`);
    this.actGetDropdowns(`?status`);
  }
};
</script>