<template>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-70">
        <MultipleSelectAnswer 
          v-model.trim="form.quiz_options" 
          :isCreateQuiz="isCreateQuiz"
          :items="items" />
      </div>
    </div>
  </template>
  
  <script>
  import MultipleSelectAnswer from "@/components/molecule/question/MultipleSelectAnswer";
  
  export default {
    components: {
      MultipleSelectAnswer,
    },
    props: {
      isCreateQuiz: {
        type: Boolean,
        default: false,
      },
      answerOption: {
        type: Array,
        default: null,
      },
    },
    data() {
      return {
        defaultItems: [
            { id: 1,is_correct_answer: false },
            { id: 2, is_correct_answer: false },
            { id: 3, is_correct_answer: false },
            { id: 4,  is_correct_answer: false },
        ],
        items: [],
        form: {
          quiz_options: [],
        },
      };
    },
    created() {
        this.updateItemsAndForm();
    },
    watch: {
        answerOption: "updateItemsAndForm",
    },
    methods: {
        updateItemsAndForm() {
            if (this.answerOption.length) {
                this.items = this.answerOption.map(item => ({
                ...item,
                is_correct_answer: item.is_correct_answer ?? false, 
                }));
                this.form.quiz_options = [...this.answerOption];
            } else {
                this.items = [...this.defaultItems];
                this.form.quiz_options = [...this.defaultItems];
            }
        },
    }
  };
  </script>
  