<template>
  <div class="p-6 ">
      <DialogTitle :label="question.answer_type? question.answer_type: 'Question index goes to here '" align="center"/>
      <div>
        <Content label="QID" :text="question.quiz_id"/>
      </div>

      <div class="md-layout md-gutter" >
        <div class="md-layout-item md-xsmall-size-100 md-large-size-30 md-large-cu_size-38">
          <Content label="Question Type" :text="question.quiz_type_title" />
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-large-size-70 md-large-cu_size-58">
          <Content label="Answer Type" :text="question.answer_type ? question.answer_type : ''" />
        </div>
      </div>

      <div>
        <div class="my-2 md-layout md-gutter">
          <div class="md-layout-item md-xsmall-size-100 md-large-size-30 md-large-cu_size-38">
            <p class="text-uppercase text-victoria mb-2">TAGS</p>
            <span class="flex">
              <div v-for="(tag,i) in question.tags" :key="i" class="bg-victoria text-white mr-1 px-1 text-base line-height-27 rounded">{{tag}}</div>
            </span>
          </div>
          <div class="md-layout-item md-xsmall-size-100 md-small-size-25 md-large-size-25">
            <Content label="Negative Narking" content-width="w-full" :text="question.negative_marking ? 'Yes' : 'No'" />
          </div>
        </div>
        <div class="my-2" v-if="question.index != null">
          <p class="text-uppercase text-victoria mb-2">Index</p>
          <div class="text-base line-height-27 rounded" v-html="question.index"></div>
        </div>
        <div class="my-2">
          <p class="text-uppercase text-victoria mb-2"> Transcript</p>
          <p class="p-1 pt-2 pb-4" v-if="question.transcript != null" v-html="question.transcript"></p>
          <p class="p-1 pt-2 pb-4" v-if="question.transcript == null">No data</p>
        </div>
        <div class="bg-lite-ash px-2 py-1">
          <Content label="" text="QUESTION" />
          <div class="my-2" v-if="question.title">
            <p class="text-uppercase text-victoria mb-2">TITLE</p>
            <p class="p-1 pt-2 pb-4" v-html="question.title"></p>
          </div>
          <div class="my-2" v-if="question.audio_resource_path">
            <p class="text-uppercase text-victoria mb-2">Audio</p>
            <AudioFile :src="question.audio_resource_path"/>
          </div>
          <div class="my-2" v-if="question.image_resource_path">
            <p class="text-uppercase text-victoria mb-2">Image</p>
            <img :src="question.image_resource_path"/>
          </div>
          <div class="my-2" v-if="question.video_resource_path">
            <p class="text-uppercase text-victoria mb-2">Video</p>
            <span v-if="question.video_resource_path.match('youtube')">
              <iframe 
                id="player" 
                type="text/html" 
                style="width: 60%;height: 45vh !important;"
                :src="videoUrl(question.video_resource_path)"
                frameborder="0">
              </iframe>
            </span>  
            <span v-if="!question.video_resource_path.match('youtube')">
              <video controls style="width: 60%;">
                <source :src="question.video_resource_path" type="video/mp4">
                Your browser does not support HTML5 video.
              </video>
            </span>
          </div>
        </div>
        <div class="my-2">
            <div class="text-base line-height-27" v-if="question.quiz_options.length != empty">
                <p class="text-uppercase text-victoria mb-2">Answer</p>
                <span>
                    <span class="student_answer_checked">
                        <span class="student_answer_checked">
                            <li v-for="(item, index) in question.quiz_options" :key="index" class="my-3 flex align-center">
                                <md-checkbox 
                                    v-if="question.answer_type == 'MCQ-CHOOSE-MULTIPLE-ANSWER'"
                                    disabled :disable="disable"  :value="item.is_correct_answer" v-model="disable" :checked="item.is_correct_answer"> 
                                </md-checkbox>
                                <md-radio 
                                    v-if="question.answer_type == 'MCQ-CHOOSE-SINGLE-ANSWER'"
                                    disabled :disable="disable"  :value="item.is_correct_answer" v-model="disable" :checked="item.is_correct_answer"> 
                                </md-radio>
                                <Content :text="item.value" class="w-80 teacher_view_q"/>
                               
                            </li>
                            <p v-html="question.answer"></p>
                        </span>
                    </span>
                </span>
            </div>
        </div>
      </div>

      <div class="md-layout md-gutter" style="max-width:77%">
        <div v-if="this.question.answer_time" class="md-layout-item md-xsmall-size-100 md-small-size-50 md-large-size-50">
          <Content label="Preparing Time" :text="perseTime(this.question.answer_time)" />
        </div>
        <div v-if="this.question.answer_time" class="md-layout-item md-xsmall-size-100 md-small-size-50 md-large-size-50">
            <Content label="Answer Time" :text="perseTime(this.question.answer_time)" />
        </div>
      </div>

      <div class="md-layout md-gutter">
        <div class="md-layout-item md-xsmall-size-100 md-small-size-25">
          <Content label="Status" content-width="w-full" :text="(question.active == 1) ? 'Published': 'Draft'" text-color="text-green" />
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-small-size-25">
          <Content label="Created By" content-width="w-full" :text="question.created_by" />
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-small-size-25 md-large-size-25">
            <Content label="Created At" content-width="w-full" :text="question.created_at" />
        </div>
      </div>
      
      <div class="flex justify-between mt-6">
        <md-button
          :disabled="!question.prev"
          :class="!question.prev ? 'bg-periwinkle-gray': 'bg-victoria'"
          class="ml-0 text-white text-uppercase rounded"
          @click="getAnotherQuestionDetails(question.prev)">Previous</md-button>
        <md-button
          :disabled="!question.next"
          :class="!question.next ? 'bg-periwinkle-gray': 'bg-victoria'"
          class="mr-0 text-white text-uppercase rounded"
          @click="getAnotherQuestionDetails(question.next)">Next</md-button>
      </div>
  </div>
</template>

<script>
import { DialogTitle } from "@/components";
import Content from "@/components/atom/Content";
import AudioFile from "@/components/molecule/question/audio.vue";
import { getEmbeddedUrl } from "@/utils/videoHelper";
import { mapActions, mapGetters, mapMutations } from 'vuex';
import data from "@/data/pte-quiz/data";
export default {
  components: {
    DialogTitle,
    Content,
   AudioFile,
  },
  data() {
      return {
          section: '',
          answerType: data.answerTypes,
          question_type: [],
          showStudyGuideDialog: false,
          showSampleAnswerDialog: false,
          showExplanationsDialog: false,
          disable: 1,
      }
  },
  computed: {
    ...mapGetters({
      question: "question/getSingleQuiz"
    }),
    usedFor() {
      return this.question.used_for;
    },
  },
  mounted(){
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog"
    }),
    ...mapActions({
      actQuizDetails: "question/actQuizDetails"
    }),
    perseTime(time) {
      let menute = Math.trunc(parseInt(time) / 60);
      let second = Math.trunc(parseInt(time) % 60);
      return `${menute} M, ${second} S`;
    },
    videoUrl(url) {
      return getEmbeddedUrl(url);
    },
    getAnotherQuestionDetails(quizId) {
      this.actQuizDetails(quizId)
    },
    StudyGuideDetail(){
      this.showStudyGuideDialog = true;
    },
    SampleAnswerDetail(){
      this.showSampleAnswerDialog = true;
    },
    ExplanationsDetail(){
       this.showExplanationsDialog = true;
    }
  }
}
</script>

<style>
.md-image__view{
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
</style>