<template>
    <video id="myVideo" class="video-js vjs-default-skin" playsinline></video>
</template>

<script>
    /* eslint-disable */
    import 'video.js/dist/video-js.css'
    import 'videojs-record/dist/css/videojs.record.css'
    import videojs from 'video.js'

    import 'webrtc-adapter'
    import RecordRTC from 'recordrtc'

    // the following imports are only needed when you're recording
    // audio-only using the videojs-wavesurfer plugin
    /*
    import WaveSurfer from 'wavesurfer.js';
    import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';
    WaveSurfer.microphone = MicrophonePlugin;

    // register videojs-wavesurfer plugin
    import videojs_wavesurfer_css from 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css';
    import Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer.js';
    */

    import Record from 'videojs-record/dist/videojs.record.js'


import { putObject} from "@/store/upload";
const AWS = require('aws-sdk');
const spacesBaseUrl = new AWS.Endpoint(process.env.VUE_APP_DIGITALOCEAN_SPACES_ENDPOINT);
const urlMarge = spacesBaseUrl.protocol+'//'+process.env.VUE_APP_DIGITALOCEAN_SPACES_BUCKET+'.'+spacesBaseUrl.host
const baseUrl =urlMarge+'/'+process.env.VUE_APP_DIGITALOCEAN_SPACES_FOLDER+'/'
    export default {
        data() {
            return {
                player: '',
                path:'quiz',
                options: {
                    controls: true,
                    autoplay: false,
                    fluid: false,
                    loop: false,
                    width: 340,
                    height: 200,
                    bigPlayButton: false,
                    controlBar: {
                        volumePanel: false
                    },
                    plugins: {
                        // configure videojs-record plugin
                        record: {
                            audio: true,
                            video: true,
                            maxLength: 180,
                            debug: true
                        }
                    }
                }
            };
        },
        mounted() {
            /* eslint-disable no-console */
            this.player = videojs('#myVideo', this.options, () => {
                // print version information at startup
                var msg = 'Using video.js ' + videojs.VERSION +
                    ' with videojs-record ' + videojs.getPluginVersion('record') +
                    ' and recordrtc ' + RecordRTC.version;
                videojs.log(msg);
            });

            // device is ready
            this.player.on('deviceReady', () => {
                console.log('device is ready!');
            });

            // user clicked the record button and started recording
            this.player.on('startRecord', () => {
                console.log('started recording!');
            });

            // user completed recording and stream is available
            this.player.on('finishRecord', () => {
                // the blob object contains the recorded data that
                // can be downloaded by the user, stored on server etc.
                console.log('finished recording: ', this.player.recordedData);
                this.file_url = URL.createObjectURL(this.player.recordedData)
                //path assign here
                const paths = this.path +'/video/'+ this.player.recordedData.name 
                this.file_path = paths.toLowerCase();
                this.filepath = this.path+'/video'
                putObject(this.player.recordedData, this.filepath, "public-read")
                this.$emit('input',baseUrl+paths);
                console.log(baseUrl+paths,'baseUrl+paths')
            });

            // error handling
            this.player.on('error', (element, error) => {
                console.warn(error);
            });

            this.player.on('deviceError', () => {
                console.error('device error:', this.player.deviceErrorCode);
            });
        },
        beforeDestroy() { 
            if (this.player) {
                this.player.dispose();
            }
        }
    }
</script>