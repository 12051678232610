<template>
    <div class="md-layout md-gutter">
        <div class="md-layout-item md-size-100">
            <MCQAnswer 
              label-outline block 
              :items="items" 
              v-model.trim="form.quiz_options" />
        </div>
  
    </div>
  </template>
  
  <script>
  import MCQAnswer from "@/components/molecule/question/MultipleChoiseAnswer";
  export default {
      components: {
          MCQAnswer
      },
      props: {
        answerOption: {
          type: Array,
          default: null,
        },
      },
      data() {
        return {
          defaultItems: [
            { id: 1,is_correct_answer: false },
            { id: 2, is_correct_answer: false },
            { id: 3, is_correct_answer: false },
            { id: 4,  is_correct_answer: false },
          ],
          items: [],
          form: {
            quiz_options: [],
          },
        };
      },
      created() {
        if (this.answerOption && this.answerOption.length) {
          this.items = [...this.answerOption];
          this.form.quiz_options = [...this.answerOption];
        } else {
          this.items = [...this.defaultItems];
          this.form.quiz_options = [...this.answerOption];
        }
      },
      watch: {
        answerOption(newValue) {
          if (newValue && newValue.length) {
            this.items = [...newValue];
            this.form.quiz_options = [...newValue];
          } else {
            this.items = [...this.defaultItems];
            this.form.quiz_options = [...newValue];
          }
        },
      },
  }
  </script>
  